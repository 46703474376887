
function About() {
    return (<div className="About-Body">
        <h2 className="Main-Text">
            Dumb as Ape. Slow as Turtle.
        </h2>
        <p className="Body-Text-1">
            What follows is the beginning of the greatest financial, technical, and spiritual collaboration this world has ever known.
        </p>
        <p className="Body-Text-2">
            We may be on our back, but make no mistake... we will roll over.
        </p>
    </div>);
}
export default About;
