const INVESTMENTS = [
    { company: "Cerebral", amount: "50,000" },
    { company: "Cargo", amount: "50,000" },
    { company: "Heads", amount: "15,000" },
    { company: "Clearstep", amount: "7,500" },
    { company: "SpaceLoud", amount: "40,000" },
    { company: "Chemistry", amount: "90,000" },
    { company: "KEY", amount: "200,000" },
    { company: "Trivirium", amount: "12,500" },
    { company: "Nouri", amount: "25,000" },
]

export default INVESTMENTS
